import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder } from '@angular/forms'
import { FormularFeldValidatoren } from '../../../validators/formular-feld-validatoren'
import {
  AktivesEingabefeldHandlerService,
} from '../../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service'
import { Observable, Subscription } from 'rxjs'
import { Einkommen, EinkommenDTO, EinkommensTyp } from '../model/einkommen.model'
import { EinkommenService } from '../services/einkommen.service'
import { BasisEinkommenUnterformular } from '../basis-einkommen-unterformular'

@Component({
  selector: 'app-sonderzahlung',
  templateUrl: './sonderzahlung.component.html',
  styleUrls: ['./sonderzahlung.component.scss']
})
export class SonderzahlungComponent extends BasisEinkommenUnterformular implements OnInit, OnDestroy {
  sonderzahlungen: Einkommen[] = null
  sonderzahlung$: Observable<Einkommen[]>
  sonderzahlungSub: Subscription

  @HostBinding('class.formular-field-fullwidth') isFullWidth: boolean


  constructor(public override fb: FormBuilder,
              public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
              private einkommenService: EinkommenService) {
    super(fb, focusedControlHandlerService)
  }

  ngOnInit(): void {
    this.einkommenService.fetchEinkommen(EinkommensTyp.SONDERZAHLUNG)

    this.sonderzahlung$ = this.einkommenService.sonderzahlung$
    this.sonderzahlungSub = this.sonderzahlung$.subscribe((data: Einkommen[]) => {
      this.sonderzahlungen = data
    })
  }

  override setInitialValue(value: EinkommenDTO) {
    value = this.checkInitialValueForExistingEinkommen(value, this.sonderzahlungen)
    super.setInitialValue(value)
  }

  override buildFormControl(): AbstractControl {
    return this.fb.group({
      netto: ['', [
        FormularFeldValidatoren.maximum(this.maximum),
        FormularFeldValidatoren.minimum(this.minimum)
      ]],
      brutto: [{ value: '', disabled: true }, [
        FormularFeldValidatoren.maximum(this.maximum),
        FormularFeldValidatoren.minimum(this.minimum)
      ]],
      bestandsId: [{ value: '', disabled: true }],
      version: ['']
    }, { validators: FormularFeldValidatoren.nettoLessThanOrEqualToBrutto() })
  }

  toggleControlState(nettoIsSet: boolean): void {
    const bruttoControl = this.formControl.get('brutto')
    const sonderzahlungOptionControl = this.formControl.get('bestandsId')

    if (bruttoControl && sonderzahlungOptionControl) {
      if (nettoIsSet) {
        bruttoControl.enable()
        sonderzahlungOptionControl.enable()
      } else {
        bruttoControl.disable()
        sonderzahlungOptionControl.disable()
      }
    }
  }

  override enableFormControls(nettoValue: string): void {
    this.toggleControlState(!!nettoValue)
  }

  override updateVersion(selectedBestandsId: string): void {
    const selectedSonderzahlung = this.sonderzahlungen.find(sonderzahlung => sonderzahlung.bestandsId === selectedBestandsId)

    if (selectedSonderzahlung) {
      this.formControl.get('version').patchValue(selectedSonderzahlung.version)
    }
  }

  ngOnDestroy(): void {
    if (this.sonderzahlungSub) {
      this.sonderzahlungSub.unsubscribe()
    }
    this.einkommenService.resetSonderzahlung()
  }
}
