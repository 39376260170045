import { Injectable } from '@angular/core'
import { BackendConfigObject, ConfigOptions } from '../model/config-options.model'
import { ModalButtonConfig } from '../model/modal-button-config.model'
import { BehaviorSubject } from 'rxjs'
import { UploadService } from '../upload.service'
import { FinalizedCloseReason, Flow, UserContext } from '../model/events'
import { BucketAccessInfo } from '../model/buckets.model'
import { BucketsHttpService } from './buckets-http.service'
import { Stage } from '../stage/stage'
import { Kunde } from '../model/kunde.model'
import { KundenHttpService } from './kunden-http.service'
import { take } from 'rxjs/operators'
import { Payload } from '../model/payload'

@Injectable({
  providedIn: 'root',
})
export class UploadDialogService {

  private readonly dummyButton: ModalButtonConfig = {
    label: '',
    slot: 'secondary-actions',
    id: 'dummy',
    type: 'text',
    clickFn: null,
  }
  private backendConfigOptions: BackendConfigObject

  private modalTitelSubject = new BehaviorSubject<string>('Dokumente hochladen')
  public modalTitle$ = this.modalTitelSubject.asObservable()

  private modalButtonsSubject = new BehaviorSubject<ModalButtonConfig[]>([this.dummyButton])
  public modalButtons$ = this.modalButtonsSubject.asObservable()

  uploadConfigOptions: ConfigOptions = {}
  /**
   * Enthält die gültigen Kombinationen von Input-Parametern für die verschiedenen Dialoge. Es gibt noch weitere gültige
   * Kombinationen, aber die sind hier nicht aufgeführt, da sie bis dato von keinem Konsumenten benötigt werden
   *
   * Ausgehend von den Parametern 'kundennr', 'haushaltsId', 'useGenericCustomer' und 'documentUploadEndpoint'
   * Da es bei der FotoKomponente und den UploadFileDialog keine HaushaltsId gibt, wird diese nicht berücksichtigt
   * x = Parameter ist gesetzt, o = Parameter ist nicht gesetzt
   * @private
   */
  private inputParamsValidationMap: { [key: string]: string[] } = {
    // kundenNr, haushaltsId, useGenericCustomer, documentUploadEndpoint
    'TOOL_DIALOG': ['oooo', 'xooo', 'oxoo', 'xxoo', 'xoox', 'ooxx'],
    'FILE_FOTO_DIALOG': ['xoo', 'xox', 'oxx', 'xxx'],
  }

  readonly defaultDTAuftragConfigOptions: ConfigOptions = {
    useGenericCustomer: false,
    documentUploadEndpoint: null,
    maxCountOfDocuments: 39,
    mergeToPdfByDefault: false,
    showMergeToPdfToggle: true,
    checkEWE: true,
    acceptedFileTypes: ['image', 'pdf'],
    resultFileSizeInMByte: 100,
    userContext: 'VB',
    convertToPdfBeforeUpload: true,
  }

  readonly acceptedFileTypesMapping: Map<string, string> = new Map<string, string>([
    ['image', 'image/*'],
    ['pdf', 'application/pdf']
  ])


  constructor(private uploadService: UploadService, private bucketsHttpService: BucketsHttpService, private kundenHttpService: KundenHttpService, private stage: Stage) {
    this.uploadConfigOptions = { ...this.defaultDTAuftragConfigOptions }
  }

  initConfigOptions(configOptions?: ConfigOptions, flow?: Flow): ConfigOptions {
  const unmappedAcceptedFileTypes = configOptions.acceptedFileTypes && configOptions.acceptedFileTypes.length > 0 ? configOptions.acceptedFileTypes : Array.from(this.acceptedFileTypesMapping.keys())

  const mappedAcceptedFileTypes = unmappedAcceptedFileTypes
    .filter(fileType => this.acceptedFileTypesMapping.has(fileType))
    .map(fileType => this.acceptedFileTypesMapping.get(fileType))

    this.uploadConfigOptions = {
      ...this.defaultDTAuftragConfigOptions,
      ...configOptions,
      acceptedFileTypes: mappedAcceptedFileTypes
    }

    // Dient für den Übergang, bis WebVP auf ConfigOptions umgestellt hat und denFlow nicht mehr mit gibt
    // TODO: [DT-713] Ausbauen, wenn WebVP umgestellt hat
    if (flow === Flow.ZUSATZDOKUMENTE) {
      this.uploadConfigOptions.documentUploadEndpoint = `https://vertragsabschluss.${this.stage.getStage()}.dvag`
      this.uploadConfigOptions.showMergeToPdfToggle = false
      this.uploadConfigOptions.mergeToPdfByDefault = true
      // ************ Anfang ****************
      // DT-404 / WEBVP-12855 Diese Änderung ist eine temporäre Lösung für WebVP, damit für Minderjährige Dokumente hochgeladen werden können
      // Die folgenden Zeilen müssen einfach entfernt werden, sobald die generelle Lösung über den EinwilligungsService vorhanden ist.
      this.uploadConfigOptions.checkEWE = false
      // ************* Ende ***************
    }

    if(!this.uploadConfigOptions.documentUploadEndpoint) {
      this.uploadConfigOptions.checkEWE = true
    }

    console.log('Finished init configOptions', this.uploadConfigOptions)
    return this.uploadConfigOptions
  }

  validateInputParameters(validaionForDialog: 'TOOL_DIALOG' | 'FILE_FOTO_DIALOG', configOptions?: ConfigOptions, kundennr?: string, haushaltsId?: string, userContext?: string): {
    status: 'OK' | 'ERROR',
    message?: string
  } {
    const validInputParameterCombinations = this.inputParamsValidationMap[validaionForDialog]
    let inputParams = kundennr ? 'x' : 'o'
    if (validaionForDialog === 'TOOL_DIALOG') {
      inputParams += haushaltsId ? 'x' : 'o'
    }
    inputParams += configOptions.useGenericCustomer ? 'x' : 'o'
    inputParams += configOptions.documentUploadEndpoint ? 'x' : 'o'

    if(configOptions.resultFileSizeInMByte > 100) {
      console.error('resultFileSizeInMByte darf nicht größer als 100 MB sein')
      return { status: 'ERROR', message: `Dialog was opened with invalid input parameter: resultFileSizeInMByte of ${configOptions.resultFileSizeInMByte} exceeds maximun of 100 MB` }
    }

    if (!validInputParameterCombinations.includes(inputParams)) {
      console.error('Falsche Kombination von Input-Parametern: ', inputParams)
      return { status: 'ERROR', message: 'Dialog was opened with invalid combination of input parameters.' }
    }
    if (Array.isArray(configOptions.acceptedFileTypes) && configOptions.acceptedFileTypes.some(fileType => !this.acceptedFileTypesMapping.has(fileType))) {
      console.error(`Es werden nur die FileTypes ${Array.from(this.acceptedFileTypesMapping.keys()).join(" , ")} akzeptiert.`)
      return { status: 'ERROR', message: `AcceptedFileTypes can only contain ${Array.from(this.acceptedFileTypesMapping.keys()).join(" , ")}.` }
    }
    const mergedUserContext = userContext === UserContext.ENDKUNDE? userContext : configOptions.userContext ?? 'VB'
    const isEndkunde = mergedUserContext === UserContext.ENDKUNDE
    if(isEndkunde && configOptions.useGenericCustomer || isEndkunde && !kundennr) {
      console.error('Endkunde kann nicht ohne Kundennummer verwendet werden')
      return { status: 'ERROR', message: 'Endkunde can not be used without KundenNummer' }
    }


    return { status: 'OK' }
  }

  /**
   * Führt ein Mapping der ConfigOptions auf ein BackendConfigObject durch
   * @param valideKundennummer (optional) - Sollte nur gesetzt werden, wenn die Kundennummer validiert wurde
   * @private
   */
  private mapConfigOptionsToBackendConfigObject(valideKundennummer?: string): BackendConfigObject {
    return <BackendConfigObject> {
      kundennummer: this.uploadConfigOptions.useGenericCustomer ? null : valideKundennummer,
      documentUploadEndpoint: this.uploadConfigOptions.documentUploadEndpoint?.length > 0 ? this.uploadConfigOptions.documentUploadEndpoint : null,
      checkEWE: this.uploadConfigOptions.useGenericCustomer ? false : this.uploadConfigOptions.checkEWE,
      maxCountOfDocuments: ( !this.uploadConfigOptions.showMergeToPdfToggle && this.uploadConfigOptions.mergeToPdfByDefault) ? 1 : this.uploadConfigOptions.maxCountOfDocuments,
      resultFileSizeInMByte: this.uploadConfigOptions.resultFileSizeInMByte,
      convertToPdfBeforeUpload: this.uploadConfigOptions.convertToPdfBeforeUpload,
    }
  }

  showModalButtons(buttons: ModalButtonConfig[]) {
    this.modalButtonsSubject.next(buttons ?? [this.dummyButton])
  }

  setModalTitle(headline: string) {
    this.modalTitelSubject.next(headline)
  }

  resetService() {
    this.uploadConfigOptions = { ...this.defaultDTAuftragConfigOptions }
    this.backendConfigOptions = undefined
    this.modalButtonsSubject.next([this.dummyButton])
    this.setModalTitle('Dokumente hochladen')
  }

  async createBucket(kundennr?: string): Promise<BucketAccessInfo> {
    this.backendConfigOptions = this.mapConfigOptionsToBackendConfigObject(kundennr)
    return new Promise<BucketAccessInfo>((resolve, reject) => {
      this.bucketsHttpService.erstelleBucket(this.backendConfigOptions).pipe(
        take(1),
      ).subscribe({
        next: bucket => {
          resolve(bucket)
        },
        error: () => {
          console.warn('Beim Erstellen des Buckets ist ein Fehler aufgetreten: ', this.backendConfigOptions)
          reject(null)
        },
      })
    })
  }

  uploadDokument(payloads: Payload[], bucketId: string, mergeToPdf: boolean) {
    // call startUpload
    console.log(payloads)
    this.uploadService.startUpload(
      bucketId,
      payloads,
      mergeToPdf,
      this.backendConfigOptions,
    )
    this.resetService()
  }

  closeUploadProcess(reason: FinalizedCloseReason, errormessage?: string) {
    const backendConfig = this.backendConfigOptions ?? {}
    this.resetService()
    console.log('closeUploadProcess: ', reason, errormessage)
    this.uploadService.closeUploadProcess(backendConfig, reason, errormessage)
  }

  ladeKunde(kundennummer: string): Promise<Kunde> {
    return new Promise<Kunde>((resolve, reject) => {
      this.kundenHttpService.ladeKunde(kundennummer).pipe(
        take(1),
      ).subscribe({
        next: kunde => {
          console.log('Kunde wurde geladen: ', kunde)
          if (!this.uploadConfigOptions.checkEWE) {
            kunde.eweZugestimmt = true
          }
          resolve(kunde)
        },
        error: error => {
          console.log('Fehler beim Laden des Kunden: ', error)
          reject()
        },
      })
    })
  }
}
