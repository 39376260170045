import { ErrorHandler, Inject, Injectable } from '@angular/core'
import { Stage } from '../../../projects/upload-components/src/lib/stage/stage'
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js'
import { ApplicationInsights, ICustomProperties, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { Router } from '@angular/router'
import { EnvironmentInfoService } from '../../../projects/environment-info/src/lib/environment-info.service'
import { AuftragStatus } from '../enums/auftrag.enums'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { DOCUMENT } from '@angular/common';

export enum Benutzer {
  Mitbenutzer = 'Mitbenutzer',
  Vertreter = 'Vertreter',
  Assistent = 'Assistent',
  Hauptnutzer = 'Hauptnutzer'
}

export enum UploadDialogType {
  File = 'file',
  Camera = 'camera',
  Tool = 'tool'
}

export enum DT_FE_CUSTOM_EVENT {
  APP_GESTARTET = 'DT_FE_APP_GESTARTET',
  UPLOAD_DIALOG_GEOEFFNET = 'DT_FE_UPLOAD_DIALOG_GEOEFFNET',
  ANGABEN_UEBERNOMMEN = 'DT_FE_ANGABEN_UEBERNOMMEN',
  UPLOAD_VARIANTE = 'DT_FE_UPLOAD_VARIANTE',
  UPLOAD_ABGESCHLOSSEN = 'DT_FE_UPLOAD_ABGESCHLOSSEN',
  KUNDENZUORDNUNG_GEAENDERT = 'DT_FE_KUNDENZUORDNUNG_GEAENDERT',
  FEHLER_AUFGETRETEN = 'DT_FE_FEHLER_AUFGETRETEN',
  UMFRAGE_GESTARTET = 'DT_FE_UMFRAGE_GESTARTET',
  CLICKANDCOLLECT = 'DT_FE_CLICKANDCOLLECT',
  UPLOADED_DOCUMENT = 'DT_FE_DOKUMENT_HOCHGELADEN', // Wird für jedes Dokument eines Buckets getriggert
  UPLOADER_ENDPOINT = 'DT_FE_DOKUMENTEN_UPLOAD_ENDPUNKT',
  UPLOADER_AUFTRAG = 'DT_FE_DOKUMENTEN_UPLOAD_AUFTRAG',
  DOKUMENTENVIEWER_ACTION = 'DT_FE_DOKUMENTENVIEWER_ACTION', // zoom, Auf_Breite_anpasssen, ganzes_Dokument_anzeigen, Lösche_Auftrag, Download, nächste_Seite, vorherige_Seite
  LEISTUNGSRADAR_GEOEFFNET = 'DT_FE_LEISTUNGSRADAR_GEOEFFNET',
  DASHBOARD_LINK_GEOEFFNET = 'DT_FE_DASHBOARD_LINK_AUFGERUFEN', // Datenschutz, Impressum
  DASHBOARD_SORTIERUNGSWECHSEL = 'DT_FE_DASHBOARD_SORTIERUNGSWECHSEL',
  DASHBOARD_TABWECHSEL = 'DT_FE_DASHBOARD_TABWECHSEL', // Tab Offene / Bearbeitet
  QR_UPLOAD_GESTARTET = 'DT_FE_APP_QR_UPLOAD_GESTARTET',
  IMAGE_CROPPED = 'DT_FE_IMAGE_CROPPED'
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private connString: string
  appInsights: ApplicationInsights

  private angularPlugin = new AngularPlugin()
  private clickPluginInstance = new ClickAnalyticsPlugin()
  private clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true,
    },
  }

  private uploadVariante: string
  private benutzertyp: string
  private deviceCustomDimensions: ICustomProperties

  constructor(private stage: Stage,
              private router: Router,
              private environmentInfoService: EnvironmentInfoService,
              @Inject(DOCUMENT) private document: Document,
  ) {
    console.log('ApplicationInsightsService created')
  }

  init() {
    try {
      this.initApplicationInsights()
      this.deviceCustomDimensions = {
        geraetekategorie: this.checkDevice(),
        useragent: this.environmentInfoService.getUseragent(),
        maxTouchPoints: this.environmentInfoService.getMaxTouchPoints()
      }
      console.log('Init ApplicationInsights')
    } catch (e) {
      console.log('Init ApplicationInsights  failed', e)
    }
  }

  private initApplicationInsights() {
    const stage = this.stage.getStage()
    switch (stage) {
      case 'localhost' :
      case 'entwicklung':
        this.connString = 'InstrumentationKey=7113ba28-0a31-4a86-9b3f-b211245f5f9a;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
        break
      case 'integration':
        this.connString = 'InstrumentationKey=359f2607-8591-4cfe-b345-b191fa74b9f4;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
        break
      case 'abnahme':
        this.connString = 'InstrumentationKey=6a774402-3e0f-4ec9-ad0f-bb880b1eff6d;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
        break
      case 'produktion':
        this.connString = 'InstrumentationKey=16b781e0-82b4-4ec5-b9d5-d6805d2396ea;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
        break
      default:
        this.connString = 'InstrumentationKey=7113ba28-0a31-4a86-9b3f-b211245f5f9a;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/'
    }

    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        maxBatchInterval: 0,
        connectionString: this.connString,
        disableCookiesUsage: true,
        extensions: [this.angularPlugin, this.clickPluginInstance],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
            errorServices: stage === 'produktion' ? [] : [new ErrorHandler()], // Damit die Errors weiterhin auf der Konsole geloggt werden außer in Produktion.
          },
          [this.clickPluginInstance.identifier]: this.clickPluginConfig,
        },
      },
    })

    this.appInsights.loadAppInsights()

    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      let itemTags = envelope.tags
      if (itemTags) {
        itemTags = itemTags || []
        itemTags['ai.cloud.role'] = 'FE-Datenturbo'
      }
      return true
    })
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    })
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    let customProps: ICustomProperties = {
      ...this.deviceCustomDimensions,
      benutzertyp: this.benutzertyp,
    }
    if (properties) {
      customProps = { ...customProps, ...properties }
    }

    this.appInsights?.trackEvent({ name: name }, customProps)
  }

  private checkDevice(): string {
    if (this.environmentInfoService.checkDeviceIsSmartphone()) {
      return 'Smartphone'
    } else if (this.environmentInfoService.checkDeviceIsTablet()) {
      return 'Tablet'
    } else if (this.environmentInfoService.checkDeviceIsWindowsSurface()) {
      return 'WindowsSurface'
    } else {
      return 'Desktop'
    }
  }

  logAppGestartetAlsBenutzertyp(benutzer?: string, hasContextUser: boolean = false) {
    console.log(benutzer ?? 'Kein Benutzer gesetzt')
    if (!benutzer) {
      this.logError('Benutzer unbekannt')
      return
    }

    //Mitbenutzer endet mit Zahl > 0
    const isMitbenutzer = !!benutzer.match(/^[0-9]*[1-9]$/)

    // TODO: Vertreter werden nicht erkannt
    if (benutzer.startsWith('0') || benutzer === Benutzer.Assistent) {
      this.benutzertyp = Benutzer.Assistent
    } else if (isMitbenutzer) {
      this.benutzertyp = Benutzer.Mitbenutzer
    } else if (benutzer === Benutzer.Vertreter || hasContextUser) {
      this.benutzertyp = Benutzer.Vertreter
    } else {
      this.benutzertyp = Benutzer.Hauptnutzer
    }

    this.logEvent(DT_FE_CUSTOM_EVENT.APP_GESTARTET)
  }

  logUploadVarianteSelected(uploadVariante: string) {
    this.uploadVariante = uploadVariante
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_VARIANTE, {
      variante: uploadVariante,
    })
  }

  //Datenturbo, MeineApp, MeineKunden etc.
  logUploadAbgeschlossen(ursprung: string, formulartyp: string) {
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_ABGESCHLOSSEN, {
      ursprung: ursprung,
      formulartyp: formulartyp,
      variante: this.uploadVariante,
    })
  }

  logKundenzuordnungChanged() {
    this.logEvent(DT_FE_CUSTOM_EVENT.KUNDENZUORDNUNG_GEAENDERT)
  }

  logAngabenUbernommen(auftragBearbeitungsStatus: AuftragStatus, anzahlVertraege: number) {
    this.logEvent(DT_FE_CUSTOM_EVENT.ANGABEN_UEBERNOMMEN, {
      erfassungsverteilung: auftragBearbeitungsStatus,
      anzahlVertraege: anzahlVertraege.toString(),
    })
  }

  logError(ausloeser: string, error?: Error) {
    this.logEvent(DT_FE_CUSTOM_EVENT.FEHLER_AUFGETRETEN, {
      ausloeser: ausloeser,
      hostname: this.document.location.hostname,
      url: this.document.location.href
  })
    if (error) {
      this.appInsights?.trackException( {exception: error, }, {...this.deviceCustomDimensions, benutzertyp: this.benutzertyp, ausloeser: ausloeser, hostname: this.document.location.hostname, url: this.document.location.href })
    }
  }

  logUmfrageGestartet() {
    this.logEvent(DT_FE_CUSTOM_EVENT.UMFRAGE_GESTARTET)
  }

  logClickAndCollect() {
    this.logEvent(DT_FE_CUSTOM_EVENT.CLICKANDCOLLECT)
  }

  logDokumentenViewerActionClicked(actionName: string) {
    this.logEvent(DT_FE_CUSTOM_EVENT.DOKUMENTENVIEWER_ACTION, {
      actionname: actionName,
    })
  }

  logLeistungsradarLinkClicked(bereichsId, tarifId) {
    this.logEvent(DT_FE_CUSTOM_EVENT.LEISTUNGSRADAR_GEOEFFNET, {
      bereichsId: bereichsId,
      tarifId: tarifId,
    })
  }

  logImageCropped() {
    this.logEvent(DT_FE_CUSTOM_EVENT.IMAGE_CROPPED)
  }

  logOpenedUploadDialog(dialogType: UploadDialogType) {
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_DIALOG_GEOEFFNET, { dialogType: dialogType, hostname: this.document.location.hostname, url: this.document.location.href  })
  }

}


