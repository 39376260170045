<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">

  <dx-date-input
    #focusElement
    [formControlName]="field.name"
    id="{{field.name}}_wert"
    [label]="field.label"
    (focus)="storeNameOfFocusedElement(field.name)"
    [required]="field.required"
    (errorFound)="triggerError($event)"
    [errormessage]="errormessage"
    (blur)="validate($event.detail)"
    [kisynced]="field.kiSync"
    (valueChange)="handleEvent($event)"
    (valueComplete)="handleEvent($event)"
  >
  </dx-date-input>

  <div class="hinweis" id="hinweis" *ngIf="showHinweisAblaufdatumFortgeschrieben && !showHinweisFortschreiben">
    <dx-icon icon="information" size="24" id="info"></dx-icon>
    <dx-text type="its" id="hinweis-text">Ablaufdatum wurde zur Sichtbarmachung in der KI in die Zukunft gesetzt.
      <a class="button" (click)="patchValueWithOriginalValue()">Zurücksetzen</a>
    </dx-text>
  </div>
  <div class="hinweis" id="hinweisFortschreiben"
       *ngIf="showHinweisFortschreiben && !showHinweisAblaufdatumFortgeschrieben">
    <dx-icon icon="information" size="24" id="info-buttonFortschreiben"></dx-icon>
    <dx-text type="its" id="hinweis-textFortschreiben">Für Sichtbarmachung in der KI anpassen.
      <a class="button" (click)="setDateToFuture()">Datum in die Zukunft setzen</a>
    </dx-text>
  </div>
</div>
<app-readonly-template *ngIf="isReadonly" [field]="field"
                       [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
