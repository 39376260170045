export interface EinkommenDTO {
  netto: number
  brutto: number
  anzahlMonate?: number
  version: string
  bestandsId: string
}

export interface Einkommen extends EinkommenDTO{
  displayText: string
}

export enum EinkommensTyp {
  GEHALT = 'gehaelter',
  SONDERZAHLUNG = 'sonderzahlungen'
}

