import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { AuftragBearbeitenService } from '../../../../auftrag-bearbeitung/services/auftrag-bearbeiten.service'
import { Einkommen, EinkommenDTO, EinkommensTyp } from '../model/einkommen.model'
import { KundenHttpService } from '../../../../../store/services/kunden.http.service'

@Injectable({
  providedIn: 'root',
})
export class EinkommenService {
  kundenNummer: string = ''

  constructor(
    private auftragBearbeitenService: AuftragBearbeitenService,
    private personenHttpService: KundenHttpService,
  ) {
    this.auftragBearbeitenService.auftrag$
      .subscribe(auftrag => {
        if (auftrag) {
          this.kundenNummer = auftrag.kundenNummer
        }
      })
  }

  private gehaltSubject = new BehaviorSubject<Einkommen[]>([])
  private sonderzahlungSubject = new BehaviorSubject<Einkommen[]>([])
  gehalt$: Observable<Einkommen[]> = this.gehaltSubject.asObservable()
  sonderzahlung$: Observable<Einkommen[]> = this.sonderzahlungSubject.asObservable()

  setGehalt(gehalt: Einkommen[]): void {
    this.gehaltSubject.next(gehalt)
  }

  setSonderzahlung(sonderzahlung: Einkommen[]): void {
    this.sonderzahlungSubject.next(sonderzahlung)
  }

  fetchEinkommen(einkommensTyp: EinkommensTyp, selectedKundenNummer?: string): void {
    if (!this.kundenNummer) {
      return
    }

    this.personenHttpService.fetchEinkommen(selectedKundenNummer ?? this.kundenNummer, einkommensTyp)
      .subscribe((einkommen: EinkommenDTO[]) => {
        const displayEinkommen: Einkommen[] = einkommen.map(e => ({
            ...e, displayText: this.generateDisplayText(e) }))
        if (einkommensTyp === EinkommensTyp.GEHALT) {
          this.setGehalt(displayEinkommen)
        } else {
          this.setSonderzahlung(displayEinkommen)
        }
      })
  }

  private generateDisplayText(einkommen: EinkommenDTO): string {
    let nettoFormatted: string = einkommen?.netto.toFixed(2).replace('.', ',') + ' € netto'

    let bruttoFormatted: string = ''
    if (einkommen.brutto) {
      bruttoFormatted = ' / ' + einkommen.brutto.toFixed(2).replace('.', ',') + ' € brutto'
    }

    return nettoFormatted + bruttoFormatted
  }

  resetGehalt(): void {
    this.setGehalt([])
  }

  resetSonderzahlung(): void {
    this.setSonderzahlung([])
  }
}
