import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { filter, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { KeycloakApiService } from '../store/services/keycloak.api.service'


export interface VbBild {
  id: string,
  aufloesung: string,
  kategorie: string,
  url: URL,
  publicUrl: URL
}

@Injectable({ providedIn: 'root' })
export class VbAvatarService {

  private avatarApiUrl: string
  private aufloesung = '144x144'
  private kategorie = 'vbportraithomepage'

  constructor(private http: HttpClient, private keycloakApiService: KeycloakApiService) {
    this.avatarApiUrl = environment.avatarURL
  }

  getAvatarURL(): Observable<URL> {
    const vbNummer = this.keycloakApiService.getVBNummerFromToken() ?? ''
    const url = this.avatarApiUrl + vbNummer
    return this.http.get<VbBild[]>(url, { responseType: 'json' })
      .pipe(
        filter((bilder: VbBild[]) => bilder.some(bild => bild.aufloesung === this.aufloesung && bild.kategorie === this.kategorie)),
        map((bilder: VbBild[]) => bilder.find(bild => bild.aufloesung === this.aufloesung && bild.kategorie === this.kategorie)?.url)
      )

  }
}
